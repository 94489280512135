import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
// import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import FeaturePopup from "./FeaturePopup";
import useFeature from "./useFeature";
import './FeatureMap.css';
import './toastPopup.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


function DrawFeature() {
  const [promptPosition, setPromptPosition] = useState({ x: 0, y: 0 });
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptEdit, setShowPromptEdit] = useState(false);
  const [coordie, setCoordie] = useState([]);
  const [polyDetails, setPolyDetails] = useState([]);
  const [featureId, setFeatureId] = useState(null);
  const [pointCoordie, setPointCoordie] = useState(null);
  const [pointDetails, setPointDetails] = useState([]);
  const [currentFeature, setCurrentFeature] = useState('');
  const [aoiFeatureData, setAoiFeatureData] = useState()
  const [editedTitle, setEditedTitle] = useState("")
  const [editedDescription, setEditedDescription] = useState("")
  const [updatedCoordinates, setUpdatedCoordinates] = useState()
  const [disableUploadButton, setDisableUploadButton] = useState(false)


  const { postFeatureData, updateFeatureData, getAoiData } = useFeature()
  const params = useParams();
  const { mission_name, mission_id } = params
  const navigate = useNavigate();
  const location = useLocation();
  const { coordinates, aoi_id, titleEdit, descriptionEdit } = location.state || {};
  const [titleInPopup, setTitleInPopup] = useState(titleEdit)
  const [descriptionInPopup, setDescriptionInPopup] = useState(descriptionEdit)
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const draw = useRef(null);

  const disableButton = (buttonName) => {
    const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
    const pointButton = document.querySelector('.mapbox-gl-draw_point');
    const trashButton = document.querySelector('.mapbox-gl-draw_trash');

    if (buttonName === 'point') {
      if (pointButton) {
        pointButton.disabled = true;
        pointButton.style.opacity = '0.5';
        pointButton.style.cursor = 'not-allowed';
      }
    } else if (buttonName === 'polygon') {
      if (polygonButton) {
        polygonButton.disabled = true;
        polygonButton.style.opacity = '0.5';
        polygonButton.style.cursor = 'not-allowed';
      }
    } else if (buttonName === 'trash') {
      if (trashButton) {
        trashButton.disabled = true;
        trashButton.style.opacity = '0.5';
        trashButton.style.cursor = 'not-allowed';
      }
    }
  }

  const enableButton = (buttonName) => {
    const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
    const pointButton = document.querySelector('.mapbox-gl-draw_point');
    const trashButton = document.querySelector('.mapbox-gl-draw_trash');

    if (buttonName === 'point') {
      if (pointButton) {
        pointButton.disabled = false;
        pointButton.style.opacity = '1';
        pointButton.style.pointerEvents = 'auto';
        pointButton.style.cursor = 'pointer';
      }
    } else if (buttonName === 'polygon') {
      if (polygonButton) {
        polygonButton.disabled = false;
        polygonButton.style.opacity = '1';
        polygonButton.style.pointerEvents = 'auto';
        polygonButton.style.cursor = 'pointer';
      }
    } else if (buttonName === 'trash') {
      if (trashButton) {
        trashButton.disabled = false;
        trashButton.style.opacity = '1';
        trashButton.style.pointerEvents = 'auto';
        trashButton.style.cursor = 'pointer';
      }
    }
  }

  const updatePromptPositionForPolygon = (coordinates) => {
    const rectangle = coordinates.reduce((acc, coord) => {
      return {
        minX: Math.min(acc.minX, coord[0]),
        maxX: Math.max(acc.maxX, coord[0]),
        minY: Math.min(acc.minY, coord[1]),
        maxY: Math.max(acc.maxY, coord[1])
      };
    }, { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity });

    const x = rectangle.maxX + 10;
    const y = (rectangle.minY + rectangle.maxY) / 2;
    const point = map.current.project([x, y]);
    const canvasWidth = map.current.getCanvas().clientWidth;
    const promptWidth = 400;
    if ((canvasWidth - point.x) < promptWidth) {
      const min_points = map.current.project([rectangle.minX, rectangle.minY]);
      point.x = min_points.x - 400;
    }
    if (point.y < 50) {
      const min_points = map.current.project([rectangle.minX, rectangle.minY]);
      point.y = min_points.y + 47;
    } else if (point.y > 573 && point.y <= 709) {
      const max_points = map.current.project([rectangle.maxX, rectangle.maxY]);
      point.y = max_points.y - 125;
    } else if (point.y > 709) {
      const max_points = map.current.project([rectangle.maxX, rectangle.maxY]);
      point.y = max_points.y - 170;
    }
    setPromptPosition({ x: point.x, y: point.y + 40 });
  }

  const updatePromptPositionForPoint = (coordinates) => {
    const x = coordinates[0]; // Longitude
    const y = coordinates[1]; // Latitude
    const point = map.current.project([x, y]);
    const canvasWidth = map.current.getCanvas().clientWidth;
    const promptWidth = 400;
    if ((canvasWidth - point.x) < promptWidth) {
      point.x = point.x - 450;
    }
    if (point.y < 50) {
      point.y = point.y + 47;
    } else if (point.y > 573 && point.y <= 709) {
      point.y = point.y - 125;
    } else if (point.y > 709) {
      point.y = point.y - 170;
    }
    setPromptPosition({ x: point.x + 50, y: point.y + 40 });
  }

  //for adding into array
  const handleData = (title, desc) => {
    const polyCount = polyDetails.length + 1;
    const pointCount = pointDetails.length + 1;
    if (currentFeature === 'Polygon' && (title === '' || title === undefined)) {
      title = polyDetails.length === 0 ? 'poly 1' : `poly ${polyCount}`;
    }
    if (currentFeature === 'Point' && (title === '' || title === undefined)) {
      title = pointDetails.length === 0 ? 'point 1' : `point ${pointCount}`;
    }
    if (currentFeature === 'Polygon') {
      setPolyDetails((prevDetails) => {
        const existingDetailIndex = prevDetails.findIndex((detail) => detail.id === featureId);
        if (existingDetailIndex !== -1) {
          const updatedDetails = [...prevDetails];
          updatedDetails[existingDetailIndex] = {
            ...updatedDetails[existingDetailIndex],
            title, description: desc, coordinates: coordie
          };
          return updatedDetails;
        } else {
          return [
            ...prevDetails,
            { title, description: desc, coordinates: coordie, id: featureId, uuid: uuidv4(), type: 'Geofence' }
          ];
        }
      });
      enableButton('polygon')
      disableButton('point')
      enableButton('trash')
    }
    else {
      setPointDetails((prevDetails) => {
        const existingDetailIndex = prevDetails.findIndex((detail) => detail.id === featureId);
        if (existingDetailIndex !== -1) {
          const updatedDetails = [...prevDetails];
          updatedDetails[existingDetailIndex] = {
            ...updatedDetails[existingDetailIndex],
            title, description: desc, coordinates: pointCoordie
          };
          return updatedDetails;
        } else {
          return [
            ...prevDetails,
            { title, description: desc, coordinates: pointCoordie, id: featureId, uuid: uuidv4(), type: 'Target Track' }
          ];
        }
      });
      enableButton('point')
      disableButton('polygon')
      enableButton('trash')
    }
  };

  const handleUpload = () => {
    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/shape_feature`);
  };

  //converting data into api format
  //for polygon
  const transformPolyData = (polyDetails) => {
    const areaOfInterests = [
      ...polyDetails.map(detail => ({
        "name": detail.title,
        "type": detail.type,
        "description": detail.description,
        "coordinates": {
        },
        "polygon": detail.coordinates[0],
      }))
    ];

    return {
      areaOfInterests,
      "name": `AOI Manager ${Number(sessionStorage.getItem('aoiManagerLength'))}`,
      "type": polyDetails.length > 1 ? "Collection" : "Single"
    };
  };

  //for point
  const transformPointData = (pointDetails) => {
    const areaOfInterests = [
      ...pointDetails.map(detail => ({
        "name": detail.title,
        "type": detail.type,
        "description": detail.description,
        "coordinates": {
          "altitude": null,
          "lat": detail.coordinates[0],
          "long": detail.coordinates[1],
          "minElevationAngle": null
        },
        "polygon": null,
      }))
    ];

    return {
      areaOfInterests,
      "name": `AOI Manager ${Number(sessionStorage.getItem('aoiManagerLength'))}`,
      "type": pointDetails.length > 1 ? "Collection" : "Single"
    };
  };

  //after closing the prompt
  const handleClose = () => {
    if (draw.current && featureId) {
      draw.current.delete(featureId);
    }
    setShowPrompt(false);
    if (polyDetails.length > 0) {
      disableButton('point');
      enableButton('polygon')
      enableButton('trash')
      setDisableUploadButton(true)
    }
    else if (pointDetails.length > 0) {
      enableButton('point')
      disableButton('polygon')
      enableButton('trash')
      setDisableUploadButton(true)
    }
    else {
      enableButton('point')
      enableButton('polygon')
      disableButton('trash')
      setDisableUploadButton(false)
    }
  };

  const handleCloseEdit = () => {
    setShowPromptEdit(false);
    if (!coordinates) {

      if (draw.current && featureId) {
        const featureExistsInPolyDetails = polyDetails.some(poly => poly.id === featureId);
        const featureExistsInPointDetails = pointDetails.some(point => point.id === featureId);

        if (!featureExistsInPolyDetails && !featureExistsInPointDetails) {
          draw.current.delete(featureId);
        }
      }

      if (polyDetails.length > 0) {
        disableButton('point');
        enableButton('polygon')
        enableButton('trash')
        setDisableUploadButton(true)
      }
      else if (pointDetails.length > 0) {
        enableButton('point')
        disableButton('polygon')
        enableButton('trash')
        setDisableUploadButton(true)
      }
      else {
        enableButton('point')
        enableButton('polygon')
        disableButton('trash')
        setDisableUploadButton(false)
      }
    }
  };

  //after clicking on close button of the map
  const handleButtonClick = () => {
    if (currentFeature === 'Polygon' && polyDetails.length > 0) {
      postFeatureData(mission_id, transformPolyData(polyDetails), "draw")

    }
    else if (currentFeature === 'Point' && pointDetails.length > 0) {
      postFeatureData(mission_id, transformPointData(pointDetails), "draw")
    }
    else if (currentFeature === '' && aoiFeatureData) {
      updateFeatureData(aoi_id, aoiFeatureData, "coordinatesEdit")
    }
    navigate(`${process.env.PUBLIC_URL}/${mission_name}/_details/${mission_id}/Features`)
  };

  // for editing the polygon or point
  const handleUpdateAoi = async (newCoordinates, newTitle, newDescription) => {
    try {
      const aoiData = await getAoiData(aoi_id);
      const updatedAoiData = {
        ...aoiData,
        ...(aoiData.type === "Geofence"
          ? {
            polygon: newCoordinates[0],
            name: newTitle !== "" ? newTitle : aoiData.name,
            description: newDescription !== "" ? newDescription : aoiData.description
          }
          : {
            coordinates: {
              altitude: 0,
              lat: newCoordinates[0],
              long: newCoordinates[1],
              minElevationAngle: 0
            },
            polygon: null,
            name: newTitle !== "" ? newTitle : aoiData.name,
            description: newDescription !== "" ? newDescription : aoiData.description
          }
        )
      };

      setAoiFeatureData(updatedAoiData)

    } catch (error) {
      console.error('Error in updating and posting AOI data:', error);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('polyDetails', JSON.stringify(polyDetails));
  }, [polyDetails])

  useEffect(() => {
    sessionStorage.setItem('pointDetails', JSON.stringify(pointDetails));
  }, [pointDetails])

  useEffect(() => {
    setPolyDetails([]);
    setPointDetails([]);
    setCurrentFeature('');
    setShowPrompt(false);
    setShowPromptEdit(false);

    mapboxgl.accessToken = "pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw";

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [0, 0],
      zoom: 1.8,
    });

    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        point: true,
      },
    });
    map.current.addControl(draw.current, "top-left");
    disableButton('trash')
    if (coordinates) {
      disableButton('polygon')
      disableButton('trash')
      disableButton('point')
    }

    map.current.on('draw.modechange', (e) => {
      if (coordinates === undefined) {
        if (e.mode === 'direct_select') {
          draw.current.changeMode('simple_select'); // Revert to selection mode if editing is attempted
        }
      }
    });

    //after loading the map
    map.current.on("load", () => {

      // for indivisual edit button, plotting the features
      if (coordinates && coordinates.lat ? (typeof coordinates.lat === 'number' && typeof coordinates.long === 'number') : (Array.isArray(coordinates) && coordinates.length > 0)) {
        const geojson = {
          type: 'Feature',
          geometry: {
            type: coordinates.lat ? 'Point' : 'Polygon',
            coordinates: coordinates.lat ? [coordinates.lat, coordinates.long] : [coordinates],
          },
        };
        const featureId = draw.current.add(geojson);
        if (coordinates.lat) {
          draw.current.changeMode('simple_select', { featureId });
        } else {
          draw.current.changeMode('direct_select', { featureId });
        }

        // map.current.addSource(coordinates.lat ? 'point' : 'polygon', {
        //   type: 'geojson',
        //   data: geojson,
        // });

        // map.current.addLayer({
        //   id: coordinates.lat ? 'point' : 'polygon',
        //   type: coordinates.lat ? 'circle' : 'fill',
        //   source: coordinates.lat ? 'point' : 'polygon',
        //   layout: {},
        //   paint: {
        //     ...(coordinates.lat ? {
        //       'circle-color': 'transparent',
        //       'circle-radius': 6,
        //     } : {
        //       'fill-color': 'green',
        //       'fill-opacity': 0.8,
        //     })
        //   }
        // });

      }
      //after creating feature
      map.current.on("draw.create", (event) => {
        const feature = event.features[0];
        setFeatureId(feature.id);
        setCurrentFeature(feature.geometry.type)
        setDisableUploadButton(true)
        disableButton('trash')

        //if its polygon
        if (feature.geometry.type === "Polygon") {
          setCoordie(feature.geometry.coordinates);
          const coordinates = feature.geometry.coordinates[0];
          updatePromptPositionForPolygon(coordinates)
          setShowPrompt(true);
          disableButton('point')
          disableButton('polygon')
        }

        //if its point
        if (feature.geometry.type === "Point") {
          setPointCoordie(feature.geometry.coordinates);
          updatePromptPositionForPoint(feature.geometry.coordinates)
          setShowPrompt(true);
          disableButton('point')
          disableButton('polygon')
        }
        const allFeatures1 = draw.current.getAll().features;
        sessionStorage.setItem('allfeatures', JSON.stringify(allFeatures1))
      });

      map.current.on("draw.update", (event) => {
        setShowPrompt(false)
        const updatedFeature = event.features[0];
        setFeatureId(updatedFeature.id);
        if (!coordinates) { //when not in edit_feature
          const allFeatures = JSON.parse(sessionStorage.getItem('allfeatures')) || [];
          const polyDetails = JSON.parse(sessionStorage.getItem('polyDetails')) || [];
          const pointDetails = JSON.parse(sessionStorage.getItem('pointDetails')) || [];
          const allFeatures1 = draw.current.getAll().features;
          const featuresToDelete = allFeatures.filter(feature => {  // Find the features that are not in either polyDetails or pointDetails, and exclude the updated feature
            const notInPolyOrPointDetails = // Check if feature is not in either polyDetails or pointDetails
              !polyDetails.some(poly => poly.id === feature.id) &&
              !pointDetails.some(point => point.id === feature.id);
            return notInPolyOrPointDetails && feature.id !== updatedFeature.id; // Ensure the feature's id is not the same as updatedFeature.id
          });

          featuresToDelete.forEach(feature => { // Loop through the features to delete them from the map
            draw.current.delete(feature.id);
          });

          const updatedFeatures = allFeatures.filter(feature => // Keep feature if it's in either polyDetails or pointDetails, or if it's the updated feature
            polyDetails.some(poly => poly.id === feature.id) ||
            pointDetails.some(point => point.id === feature.id) ||
            feature.id === updatedFeature.id
          );
          sessionStorage.setItem('allfeatures', JSON.stringify(updatedFeatures));
        }
        let newCoordinates = updatedFeature.geometry.coordinates;
        let coordinates_1 = null;
        if (!coordinates) {
          disableButton('point')
          disableButton('polygon')
          disableButton('trash')
        }
        if (updatedFeature.geometry.type === 'Polygon') {
          coordinates_1 = newCoordinates[0]
          setCoordie(updatedFeature.geometry.coordinates)
          updatePromptPositionForPolygon(coordinates_1)
        } else if (updatedFeature.geometry.type === 'Point') {
          coordinates_1 = newCoordinates
          setPointCoordie(updatedFeature.geometry.coordinates)
          updatePromptPositionForPoint(updatedFeature.geometry.coordinates)
        }
        setShowPromptEdit(true)
        if (coordinates) {
          handleUpdateAoi(newCoordinates, editedTitle, editedDescription)
        }
        // map.current.off('click', 'polygon');
        setUpdatedCoordinates(newCoordinates)
      })
      //after deleting the feature
      map.current.on('draw.delete', (event) => {
        setShowPrompt(false);
        setShowPromptEdit(false);
        const deletedIds = event.features.map((feature) => feature.id);
        setPolyDetails((prevPolyDetails) => {
          const updatedPolyDetails = prevPolyDetails.filter((poly) => !deletedIds.includes(poly.id));
          // Update point details
          setPointDetails((prevPointDetails) => {
            const updatedPointDetails = prevPointDetails.filter((point) => !deletedIds.includes(point.id));

            // Check combined conditions for button states
            const hasPolygons = updatedPolyDetails.length > 0;
            const hasPoints = updatedPointDetails.length > 0;

            if (hasPolygons) {
              disableButton('point')
              enableButton('polygon')
              enableButton('trash')
              setDisableUploadButton(true)
            }
            if (hasPoints) {
              disableButton('polygon')
              enableButton('point')
              enableButton('trash')
              setDisableUploadButton(true)
            }

            if (!hasPolygons && !hasPoints) {
              enableButton('point');
              enableButton('polygon');
              disableButton('trash');
              setDisableUploadButton(false)
            }
            return updatedPointDetails;
          });
          return updatedPolyDetails;
        });

      });
    });
    return () => {
      map.current.remove();
    };
  }, []);


  return (
    <div className="h-100 w-100 d-flex position-relative ">
      <div className="w-100 h-100 FeatureMap position-absolute" ref={mapContainerRef} />
      <div
        className="position-absolute"
        style={{
          position: "absolute",
          left: `${promptPosition.x + 170}px`,
          top: `${promptPosition.y + 70}px`,
          transform: "translate(-50%, -50%)",
        }}
      >
        {showPrompt && <div className="prompt" style={{ marginBottom: "100px" }}>
          <FeaturePopup
            close={() => handleClose()}
            addTitle={(title, desc) => {
              handleData(title, desc);
              setShowPrompt(false);
              enableButton('trash')
              if (currentFeature === 'Polygon') {
                disableButton('point')
                enableButton("polygon")
              } else if (currentFeature == 'Point') {
                enableButton('point')
                disableButton("polygon")
              }
            }}
            titleEdit={""}
            descriptionEdit={""}
          />
        </div>}
        {showPromptEdit && <div className="prompt" style={{ marginBottom: "100px" }}>
          <FeaturePopup
            close={() => handleCloseEdit()}
            addTitle={(title, desc) => {

              if (coordinates) {
                if (title !== "") {
                  setTitleInPopup(title)
                }
                if (desc !== "") {
                  setDescriptionInPopup(desc)
                }
                const updatedAoiData = {
                  ...aoiFeatureData,
                  name: title !== "" ? title : aoiFeatureData.name,
                  description: desc !== "" ? desc : aoiFeatureData.description
                };
                updateFeatureData(aoi_id, updatedAoiData, "nameEdit")
              }
              setEditedTitle(title)
              setEditedDescription(desc);
              setShowPromptEdit(false);
              if (coordinates) {
                handleUpdateAoi(updatedCoordinates, title, desc)
              } else {
                handleData(title, desc);
              }
            }}
            titleEdit={coordinates ?
              titleInPopup
              : (currentFeature === "Point" ? pointDetails.find(detail => detail.id === featureId)?.title : currentFeature === "Polygon" ? polyDetails.find(detail => detail.id === featureId)?.title : "")}

            descriptionEdit={coordinates
              ? descriptionInPopup
              : (currentFeature === "Point"
                ? pointDetails.find(detail => detail.id === featureId)?.description
                : currentFeature === "Polygon"
                  ? polyDetails.find(detail => detail.id === featureId)?.description
                  : "")}
          />
        </div>}
      </div>
      <button
        className="uploadButton"
        onClick={() => handleUpload()
        }
        disabled={coordinates || polyDetails.length || pointDetails.length || disableUploadButton}
        style={{
          opacity: coordinates || polyDetails.length || pointDetails.length || disableUploadButton ? '0.5' : '1',
          cursor: coordinates || polyDetails.length || pointDetails.length || disableUploadButton ? 'not-allowed' : 'pointer',
        }}
      />

      <button className="closeButton"
        onClick={() => {
          handleButtonClick();
        }}
        disabled={showPromptEdit || showPrompt}
        style={{
          color: showPromptEdit || showPrompt ? 'rgba(240, 240, 240, 0.5)' : 'rgba(240, 240, 240, 1)',
          cursor: showPromptEdit || showPrompt ? 'not-allowed' : 'pointer'
        }}
      >
        &times;
      </button>
    </div >
  );
}

export default DrawFeature;