//@ts-nocheck
import { useState, useEffect } from 'react';
import { Canvas } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import MissionService from '../../../../../service/MissionService';
import Common3DSat from "../../../../CommonComponents/Common3DSat";
import Loader from '../../../../../Layouts/Loader/Loader';
import { toast } from "react-toastify";
import NewLoader from '../../../../CommonComponents/NewLoader';

const FileUploader = ({ Satellite, busSizeUsage, payloadSizeUsage, isCloneTemplate }) => {
    const [model, setModel] = useState(null);
    const [isModelPresent, setModelPresent] = useState(false)
    const [modelName, setModelName] = useState()
    const [hover, setHover] = useState(false)
    const [newLoading, setNewLoading] = useState(false)
    const [load3Dmodel, loadSatModel] = useState(true)


    const loadModel = (modelUrl) => {
        const loader = new GLTFLoader();
        loader.load(modelUrl, (gltf) => {
            setModel(gltf.scene)
            setNewLoading(false);
        })
        loadSatModel(false);
    }
    const getSatelliteById = async (satelliteId) => {
        try {
            const response = await MissionService.get_satellite_by_sat_id(satelliteId);
            return response?.data ? response.data : null;
        } catch (error) {
            console.log("Error fetching satellite by ID:", error);
            return null;
        }
    };

    const getModelUrlByName = async (satelliteId, modelName) => {
        try {
            const response = await MissionService.getModelUrl(satelliteId, modelName);
            return response?.data ? response.data : null;
        } catch (error) {
            console.log("Error fetching model URL:", error);
            return null;
        }
    };
    const fetchModelData = async () => {
        setNewLoading(true);
        const satData = await getSatelliteById(Satellite.satellite_Id);
        if (satData != null && satData?.modelNames?.modelName) {
            setModelName(satData.modelNames.modelName);
            const satModelName = satData.modelNames.modelName;
            const modelUrl = await getModelUrlByName(Satellite.satellite_Id, satModelName);
            if (modelUrl != null) {
                setModelPresent(true);
                loadModel(modelUrl);
            } else {
                setNewLoading(false);
                setModelPresent(false);
            }
        } else {
            setNewLoading(false);
            setModelPresent(false);
        }

    };
    useEffect(() => {
        if (load3Dmodel) {
            fetchModelData();
        }
    }, [load3Dmodel]);

    const post3Dmodel = async (satellite_Id, formData) => {
        const response = await MissionService.postThreedModel(satellite_Id, formData)
        return response?.data ? response.data : null;
    }

    const handleButtonClick = () => {
        document.getElementById('file-input')?.click()
    }
    const saveModel = async (fileData) => {
        const formData = new FormData();
        formData.append('object', fileData);
        const isModelSaved = await post3Dmodel(Satellite.satellite_Id, formData)
        if (isModelSaved != null) {
            const satData = await getSatelliteById(Satellite.satellite_Id);
            if (satData != null) {
                setModelName(satData.modelNames.modelName);
                const satModelName = satData.modelNames.modelName;
                setModelPresent(true)
                const loader = new GLTFLoader()
                loader.load(URL.createObjectURL(fileData), (gltf) => {
                    setModel(gltf.scene);
                    setNewLoading(false);
                });
            } else {
                setModelPresent(false);
                setNewLoading(false);
            }

        } else {
            toast.error("Upload failed, please try again later");
            setModelPresent(false)
            setNewLoading(false);
        }
    }
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
        if (selectedFile) {
            if (selectedFile?.size <= MAX_FILE_SIZE) {
                setNewLoading(true)
                setModelName(selectedFile.name)
                saveModel(selectedFile)
            }
            else {
                toast.error('Size of the model should be less than 20MB')
            }
        } else {
            toast.error('No file selected')
        }
    }
    const reloadDefaultModel = () => {
        MissionService.deleteSatelliteBucket(Satellite.satellite_Id).then((res) => {
            console.log(res)
            setModelPresent(false)
        })
    }
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {newLoading && <NewLoader canvasHeight={'100vh'} canvasWidth={'50vw'} />}
            <input
                id="file-input"
                type="file"
                accept=".glb,.gltf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {!isModelPresent && (
                <div
                    onClick={handleButtonClick}
                    onMouseEnter={() => {
                        setHover(true)
                    }}
                    onMouseLeave={() => {
                        setHover(false)
                    }}
                    className="btn z-99"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: '50px',
                        left: 'auto',
                        display: 'inline-flex',
                        height: 'var(--40, 40px)',
                        padding: '8px var(--12, 12px)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 'var(--12, 12px)',
                        borderRadius: '4px',
                        border: '1px solid rgba(240, 240, 240, 0.10)',
                        backgroundColor: hover ? 'rgba(204, 245, 78, 0.20)' : '#0A0A0A',
                        visibility: isModelPresent ? true : false
                    }}
                >
                    <img src={require('../../../../../assets/images/svgs/upload_model.svg').default} alt="Upload Model" style={{ opacity: 0.6, width: '20px', height: '20px' }} />
                </div>
            )}
            {isModelPresent && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: '50px',
                        left: 'auto',
                        display: 'flex',
                        width: '320px',
                        padding: 'var(--8, 8px) var(--4, 4px) var(--8, 8px) 12px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 'var(--4, 4px)',
                        border: '0.5px solid rgba(240, 240, 240, 0.50)',
                        background: 'linear-gradient(0deg, rgba(10, 10, 10, 0.50) 0%, rgba(10, 10, 10, 0.50) 100%), rgba(255, 255, 255, 0.00)',
                        zIndex: '999'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        title={modelName}
                    >
                        <img src={require('../../../../../assets/images/svgs/satModelIcon.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--20, 20px)', height: 'var(--20, 20px)' }} />
                        <span
                            style={{
                                color: '#F0F0F0',
                                fontFamily: 'Montserrat',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                letterSpacing: '0.56px',
                                marginLeft: '2px',
                                width: '150px',
                                whiteSpace: 'nowrap',  // Prevent text wrapping
                                overflow: 'hidden',  // Hide overflow
                                textOverflow: 'ellipsis'  // Show ellipsis when text overflows
                            }}

                        >
                            {modelName}
                        </span>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'var(--4, 4px)'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                height: 'var(--24, 24px)',
                                padding: '3px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                            onClick={handleButtonClick}
                        >

                            <img src={require('../../../../../assets/images/svgs/recomputeModel.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--24, 24px)', height: 'var(--24, 24px)' }} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                height: 'var(--24, 24px)',
                                padding: '3px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',

                            }}
                            onClick={reloadDefaultModel}
                        >
                            <img src={require('../../../../../assets/images/svgs/deleteModel.svg').default} alt="3D Model" style={{ opacity: 0.6, width: 'var(--16, 16px,)', height: 'var(--16, 16px,)' }} />
                        </div>
                    </div>
                </div>
            )}
            <div
                id='infoDiv'
                style={{
                    position: 'absolute',
                    top: '50px',
                    right: '50px',
                    display: hover ? 'flex' : 'none',
                    padding: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px',
                    borderRadius: 'var(--8, 8px)',
                    opacity: '0px',
                    background: 'rgba(240, 240, 240, 0.10)',
                    backdropfilter: 'blur(20px)',

                }}>
                <img src={require('../../../../../assets/images/svgs/upload-btn-info.svg').default} alt="Upload Model Info" style={{ width: 'var(--16, 16px)', height: 'var(--16, 16px)' }} />
                <span style={{
                    color: '#FFF',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                }}>
                    File format should be glb/gltf,
                    with maximum file size of 20 MB
                </span>

            </div>
            {
                (isModelPresent) && (
                    <Canvas camera={{ position: [0, 0, 15], fov: 75 }}>
                        <ambientLight />
                        <pointLight position={[10, 0, 10]} />
                        <OrbitControls />
                        {model && <primitive object={model} position={[0, 0, 0]} />} {/* Model centered */}
                    </Canvas>
                )
            }
            {
                !isModelPresent &&
                <Common3DSat busSize={Satellite.Template?.['template-attribute']?.['bus-type']} size={busSizeUsage + payloadSizeUsage} heights="100vh" isPayloadChanges="false" cubeSize={isCloneTemplate ? 0 : 1} />
            }
        </div >
    );
}

export default FileUploader;